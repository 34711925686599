import React from "react";
import { FaFacebook } from 'react-icons/fa';
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaCamera } from "react-icons/fa";

export default function Banner() {
    return (
        <div className="container-fluid banner-bgimage h-screen bg-violet" id="banner">
            <div className="grid h-min place-items-center">
            <div className="absolute inset-x-0 top-60 bottom-0">
            <h1 className="title-font sm:text-5xl text-4xl mb-4 font-medium text-white text-center">
                        HELLO,&nbsp;&nbsp;
                        <br className="hidden lg:inline-block" />
                        <br className="hidden lg:inline-block" />
                        I'm Eric Lyons.
            </h1>
            <p className="mb-8 leading-relaxed text-2xl text-white pt-12 text-center">Empowering companies and their employees to <br/>create experiences that are accessible by all users.</p>
            <div class="flex flex-row justify-center text-5xl text-white dark:text-white sm:mt-0 pt-12">
                <a href="https://www.linkedin.com/in/ericmlyons/" className="mx-10" target="_blank"><FaLinkedin /><span className="sr-only">Linkedin</span></a>
                <a href="https://www.instagram.com/md_soccerdad/" className="mx-10 hover:underline" target="_blank"><FaInstagram /><span className="sr-only">Instagram</span></a>
                <a href="https://www.ericsphotogallery.com" className="mx-10 hover:underline" target="_blank"><FaCamera /><span className="sr-only">Eric's Photo Gallery Site</span></a>
            </div>
    </div>
                <div className="flex justify-center absolute inset-x-0 bottom-24">
                    <a href="#contact" className="button-white inline-flex text-black border-2 py-2 px-6 hover:button-black hover:text-white focus:button-black focus:text-white rounded text-lg">
                    Contact Me
                    </a>
                    <a href="#experience" className="button-white ml-4 inline-flex text-black border-2 py-2 px-6 hover:button-black hover:text-white focus:button-black focus:text-white rounded text-lg">See My Past Work</a>
                </div>
            </div>
        </div>
    );
}