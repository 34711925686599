import React from "react";
var Aboutme = require('../image/Eric_Andrea.jpg');
var AboutUs = require('../image/AboutMe.png');
var Fidelity = require('../image/fidelity.png');
var USAA = require('../image/usaa.png');
var Capone = require('../image/capone.png');
var Bofa = require('../image/boa.png');
var Ps = require('../image/ps.png');
var Google = require('../image/google.png');


export default function About() {
    return (
        <section id="about" className="text-gray-900 bg-white">
            <div className="container mx-auto">
            <div className="grid grid-cols-6 gap-3 sm:grid-cols-1 ">
            <div className="row-span-3 bg-white p-3 rounded-md"><h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 pt-24">About Me</h1></div>
            <div className="col-span-6 bg-white p-3 rounded-md">
                <p className="mb-8 leading-relaxed sm:text-lg text-base">So what is my story, and where do I even begin? You can check out my resume to learn more about my experience, so I'd like to use this space to tell you a little bit more about the who that is behind the what of my resume. 
                I am firm believer that a resume only paints part of the picture of a person. And I am going to prove it to you.</p>
                <p className="mb-8 leading-relaxed sm:text-lg text-base">I'll start quickly with what my resume does show. Years of experience in the accessibility space, covering a variety of industries, like technology, finance and government. I am a creative by choice and technical by need. 
                I combine the two to create solutions that often take the road less travelled, but reach the desired objective. I am a problem solver.</p>
                <p className="mb-8 leading-relaxed sm:text-lg text-base">Speaking of which, I built this site using React and Google searches...what a result!</p>
                <p className="mb-8 leading-relaxed sm:text-lg text-base">My career started as a designer. Helping my Mom with her graphic design business. I transitioned to the development space, which eventually opened the door for me to welcome in accessibility, which quickly became my priority. I knew that more needed to be done to ensure equal access to all, and that it needed to start with people like myself. I've 
                made a career of ensuring that accessibility is not only a consideration but a basic requirement at every company I have either worked at or worked with, but doing it in a way that recognizes the challenges and limitations designers, developers and business owners have when creating experiences. Which leads me too...</p>
                <p className="mb-8 leading-relaxed sm:text-lg text-base">...I love love LOVE to find solutions that bring me to the objective I've set for myself. That journey (and the journey I have taken on my career) may have several twists and turns, but as long as you get to where you need to go, enjoy the ride! I've learned so much in my career opening doors that may lead to a dead end, but I continue to keep opening doors until I find the right one. Progress over perfection!</p>
                <p className="mb-8 leading-relaxed sm:text-lg text-base">As for what I do outside of the daily grind. Covid helped me rediscover my passion for content creation, video and photography. Once we were able to go outside, it only helped fuel my work. I record my son's soccer games and put together highlight film. I photograph my travels and adventures. You can find them here: <a href="http://www.ericsphotogallery.com" target="_blank" className="underline">Eric's Photo Gallery</a>.</p>
            </div>
            </div>
            <div className="col-span-6 bg-white p-3 pt-8 pb-8 rounded-md"></div>
        </div>
    </section>
    );

}