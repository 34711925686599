import { Link } from "react-router-dom";

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
  } from "@material-tailwind/react";

import { DialogCustomAnimationFid } from "./Modals/ModalFid"
import { DialogCustomAnimationUSAA } from "./Modals/ModalUsaa"
import { DialogCustomAnimationCapOne } from "./Modals/ModalCapOne"
import { DialogCustomAnimationBofA } from "./Modals/ModalBofA"
import { DialogCustomAnimationPS } from "./Modals/ModalPlayStation"
import { DialogCustomAnimationGoogle } from "./Modals/ModalGoogle"
import { DialogCustomAnimationAMC } from "./Modals/ModalAMC"
import { DialogCustomAnimationCollegeboard } from "./Modals/ModalCB";
  
var Fidelity = require('../image/fidelity2.png');
var USAA = require('../image/usaa2.png');
var Capone = require('../image/capone2.png');
var Bofa = require('../image/boa2.png');
var CollegeBoard = require('../image/collegeboard2.png')
var AMC = require('../image/amc2.png')
var Ps = require('../image/ps2.png');
var Google = require('../image/google2.png');

export function SimpleCard() {
    return (
        <section id="experience" className="text-white bg-black">
            <div className="container mx-auto pb-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <div className="text-white bg-black p-3 rounded-md md:col-span-2 lg:col-span-3"><h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white pt-24">Experience</h1>
                <p className="mb-8 leading-relaxed sm:text-lg text-base pt-8">Please feel free to <a href="https://ericmlyons.com/static/media/ELyons_Current.pdf" className="underline" target="_blank">download my resume</a> if you would like to learn more about the various roles I've held.
                The cards below showcase more of the work I've done over the years with several companies, as an employee or a consultant. Great work (I think) that just doesn't fit in a word document. Hope you enjoy!</p>
                </div>
                    <Card className="mt-4 w-96">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="Fidelity logo" src={Fidelity}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                        Fidelity Investsments
                        </Typography>
                        <Typography>
                        My time at Fidelity has been spent building a strong accessibility program within Workplace Investing, but so much more exciting work is still being done!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <DialogCustomAnimationFid />
                        </CardFooter>
                    </Card>
                    <Card className="mt-4 w-96">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="USAA logo" src={USAA}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                        USAA
                        </Typography>
                        <Typography>
                        So much goodness at this place. The Chief Design Office was a collection of some of the most creative individuals I've ever been around. It reignited my creative passion!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <DialogCustomAnimationUSAA />
                        </CardFooter>
                    </Card>
                    <Card className="mt-4 w-96">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="Capital One logo" src={Capone}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                        Capital One
                        </Typography>
                        <Typography>
                        I was fortunate enought to support Capital One as a consultant, with a focus around accessibility. I know good things are still happening there!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <DialogCustomAnimationCapOne />
                        </CardFooter>
                    </Card>
                    <Card className="mt-4 w-96">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="Google logo" src={Google}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                        Google
                        </Typography>
                        <Typography>
                        This was an outstanding opportunity to travel to one of the coolest campuses I'd ever been to train some of the smartest developers in the world!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <DialogCustomAnimationGoogle />
                        </CardFooter>
                    </Card>
                    <Card className="mt-4 w-96">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="College Board logo" src={CollegeBoard}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                        College Board
                        </Typography>
                        <Typography>
                        This was the first place I ever gave an accessibility training at. Early on, loved seeing how serious this organization took accessibility, and glad I was a part of it.
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <DialogCustomAnimationCollegeboard />
                        </CardFooter>
                    </Card>
                    <Card className="mt-4 w-96">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="AMC Theaters logo" src={AMC}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                        AMC Theaters
                        </Typography>
                        <Typography>
                        What happens when one of the biggest movie chains in the country takese the right step towards being a more inclusive company? My chance to train them!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <DialogCustomAnimationAMC />
                        </CardFooter>
                    </Card>
                 </div>
                </div>
        </section>
    );
  }